import React, { useState, useEffect } from "react";
import commonColor from "../../../theme/commonColor";
import { connect } from "react-redux";
import UserActions from "../../../redux/user";
import PersonalInfo from "./personalInfo";
import TutoringInfo from "./tutoringInfo";
import TutorDocuments from "./tutorDocuments";
import StudentDocuments from "./studentDocuments";
import Settings from "./settings";
import { ReactComponent as DocumentIcon } from "../../../assets/images/profile/documents.svg";
import { ReactComponent as PersonalInfoIcon } from "../../../assets/images/profile/personal_info.svg";
import { ReactComponent as SettingsIcon } from "../../../assets/images/profile/settings.svg";
import { ReactComponent as TutoringInfoIcon } from "../../../assets/images/profile/tutoring_info.svg";

const UserInfoPanel = ({ user, saveUser, viewOnly, tutor }) => {
  const [topSelected, setTopSelected] = useState("PersonalInfo");

  useEffect(() => {
    console.log("accountType VIEW user", tutor);
  }, []);

  const renderTopSelectedTab = () => {
    if (topSelected === "PersonalInfo") {
      return (
        <div className="w-full h-full flex justify-center items-center py-4 relative">
          <PersonalInfo user={tutor || user} viewOnly={viewOnly} />
        </div>
      );
    } else if (topSelected === "TutoringInfo") {
      return (
        <div className="h-full w-full flex justify-center items-center">
          <TutoringInfo user={user} />
        </div>
      );
    } else if (topSelected === "Documents") {
      return (
        <div className="w-full md:h-full flex justify-center items-center">
          {(tutor || user).accountType === "Tutor" ? (
            <TutorDocuments
              user={tutor || user}
              saveUser={saveUser}
              viewOnly={viewOnly}
            />
          ) : (
            <StudentDocuments user={tutor || user} saveUser={saveUser} />
          )}
        </div>
      );
    } else if (topSelected === "Settings") {
      return (
        <div className="w-full h-full flex justify-center items-center pl-8 pr-8 py-12 overflow-y-scroll">
          <Settings user={user} />
        </div>
      );
    }
  };

  return (
    <div className="h-full w-full px-1 flex flex-col justify-start pt-1">
      <div className="w-full flex justify-center heightBoxHeader">
        <button
          className="ml-4 w-1/5 sm:w-1/4 pt-2 px-2 pb-1 text-center text-white text-sm focus:outline-none "
          style={{
            backgroundColor:
              topSelected === "PersonalInfo"
                ? commonColor.deepPink
                : "transparent",
            borderTopLeftRadius: "1.5rem",
            borderTopRightRadius: "1.5rem",
          }}
          onClick={() => setTopSelected("PersonalInfo")}
        >
          <div className="flex justify-center items-center">
            <PersonalInfoIcon className="w-4" />
            <p className="hidden ml-2 md:block">Personal Info</p>
          </div>
        </button>

        {user.accountType === "Tutor" && (
          <button
            className="w-1/5 sm:w-1/4 pt-2 px-2 pb-1 text-center text-white text-sm focus:outline-none"
            style={{
              backgroundColor:
                topSelected === "TutoringInfo"
                  ? commonColor.deepPink
                  : "transparent",
              borderTopLeftRadius: "1.5rem",
              borderTopRightRadius: "1.5rem",
            }}
            onClick={() => setTopSelected("TutoringInfo")}
          >
            <div className="flex justify-center items-center">
              <TutoringInfoIcon className="w-4" />
              <p className="hidden ml-2 md:block">Tutoring Info</p>
            </div>
          </button>
        )}

        <button
          className="w-1/5 sm:w-1/4 pt-2 px-2 pb-1 text-center text-white text-sm focus:outline-none"
          style={{
            backgroundColor:
              topSelected === "Documents"
                ? commonColor.deepPink
                : "transparent",
            borderTopLeftRadius: "1.5rem",
            borderTopRightRadius: "1.5rem",
          }}
          onClick={() => setTopSelected("Documents")}
        >
          <div className="flex justify-center items-center">
            <DocumentIcon className="w-4" />
            <p className="hidden ml-2 md:block">Documents</p>
          </div>
        </button>

        {!viewOnly && (
          <button
            className="w-1/5 sm:w-1/6 pt-2 px-2 pb-1 text-center text-white text-sm focus:outline-none"
            style={{
              backgroundColor:
                topSelected === "Settings"
                  ? commonColor.deepPink
                  : "transparent",
              borderTopLeftRadius: "1.5rem",
              borderTopRightRadius: "1.5rem",
            }}
            onClick={() => setTopSelected("Settings")}
          >
            <div className="flex justify-center items-center">
              <SettingsIcon className="w-4" />
              <p className="hidden ml-2 md:block">Settings</p>
            </div>
          </button>
        )}
      </div>
      <div
        className="flex-grow w-full bg-white darker_shadow h-full md:h-[calc(100%-3rem)] mt-3.5"
        style={{
          borderRadius: "1.5rem",
        }}
      >
        {renderTopSelectedTab()}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.user.user,
});

const mapDispatchToProps = (dispatch) => ({
  saveUser: (user) => dispatch(UserActions.saveUserRequest(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserInfoPanel);
